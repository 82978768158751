import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import Pager from '~/components/Common/Pager'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem } from '~/utils/helper'

interface CaseIndexProps {
  data: Query
  pageContext: {
    currentPage: number
    limit: number
    skip: number
    totalPage: number
  }
}

const caseIndexStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 72px);
  margin-right: auto;
  margin-left: auto;
  padding-right: 34px;
  padding-left: 34px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 80px;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-right: 2px;
    padding-left: 2px;
  }

  & > .title {
    ${STYLE.MIXIN.CONTENT_TITLE}
  }

  & > .contentwrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 80px;
    }
  }

  & > .contentwrapper > .content {
    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: calc(100% / 2 - 32px / 2);
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      width: calc(100% / 3 - 64px / 3);
    }
  }

  & > .contentwrapper > .content > .button {
    display: flex;
    height: 100%;
    padding: 32px;
    border-radius: 16px;
    background: var(--color-background-bright);
    color: var(--color-text);
    text-decoration: none;
  }

  & > .pagerContent {
    margin-top: 40px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
    }
  }
`

const caseIndexItemStyle = css`
  display: flex;
  flex-direction: column;

  & > .header > .image {
    width: 100%;
    height: auto;
  }

  & > .body {
    flex-grow: 1;
    margin-top: 24px;
    padding-bottom: 24px;
  }

  & > .body > .logo {
    width: auto;
    max-width: 100%;
    height: 24px;
  }

  & > .body > .company {
    margin-top: 16px;
    font-size: ${rem(12)};
    letter-spacing: 1.2px;
  }

  & > .body > .title {
    display: -webkit-box;
    margin-top: 24px;
    overflow: hidden;
    font-size: ${rem(16)};
    font-weight: bold;
    letter-spacing: -0.05em;
    line-height: 1.75;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  & > .footer {
    padding-top: 24px;
    border-top: 1px solid #e5e5e5;
  }

  & > .footer > .item {
    font-size: ${rem(12)};
    font-weight: bold;
    letter-spacing: 1.2px;
  }

  & > .footer > .number {
    margin-top: 6px;
    font-size: ${rem(12)};
  }
`

const breadcrumb = [
  {
    label: '導入事例',
  },
]

const seo = {
  title: '導入事例',
}

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    allFile(filter: { name: { eq: "cms-case" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          url
          name
          id
        }
      }
    }
    allMicrocmsStgCase(limit: $limit, skip: $skip) {
      edges {
        node {
          id
          stgCaseId
          name
          number
          title
          image_main {
            url
          }
          logo {
            url
          }
        }
      }
    }
  }
`

const CaseIndexPage = ({ data, pageContext }: CaseIndexProps) => {
  const pager = {
    path: '/case',
    currentPage: pageContext.currentPage,
    totalPage: pageContext.totalPage,
  }
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={caseIndexStyle}>
        <h1 className="title">導入事例</h1>
        <ul className="contentwrapper">
          {data &&
            data.allMicrocmsStgCase.edges.map((edge) => {
              const item = edge.node
              if (
                !item ||
                !item.id ||
                !item.name ||
                !item.image_main ||
                !item.image_main.url ||
                !item.logo ||
                !item.logo.url
              )
                return
              const gatsbyImageData = getGatsbyImageData(data.allFile, item.image_main.url)
              const gatsbyImageLogoData = getGatsbyImageData(data.allFile, item.logo.url)
              return (
                <li className="content" key={item.id}>
                  <Link to={`/case/${item.stgCaseId}`} className="button">
                    <article className={caseIndexItemStyle}>
                      <div className="header">
                        <GatsbyImage className="image" image={gatsbyImageData} alt={item.name} />
                      </div>
                      <div className="body">
                        <GatsbyImage
                          className="logo"
                          image={gatsbyImageLogoData}
                          alt={item.name}
                          objectFit="contain"
                          objectPosition="0% 50%"
                        />
                        <h2 className="company">{item.name}</h2>
                        <p className="title">{item.title}</p>
                      </div>
                      <div className="footer">
                        <p className="item">従業員数</p>
                        <p className="number">{item.number}</p>
                      </div>
                    </article>
                  </Link>
                </li>
              )
            })}
        </ul>
        <div className="pagerContent">
          <Pager pager={pager} />
        </div>
      </main>
    </Layout>
  )
}

export default CaseIndexPage
