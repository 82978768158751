import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import IconArrow from '~/images/icon/arrow.svg'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

export interface PagerTypes {
  path: string
  currentPage: number
  totalPage: number
}

interface PagerProps {
  pager: PagerTypes
}

const pagerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    column-gap: 8px;
  }

  & > .item {
    &:not(:first-of-type),
    &:not(:last-of-type) {
      color: var(--color-text);
      letter-spacing: 0;
      line-height: 0.66;
    }

    &:last-of-type {
      transform: scale(-1, 1);
    }
  }

  &.active > a > svg > path {
    fill: var(--color-text);
  }

  & > .item > .current {
    display: block;
    padding: 12px 13px;
    border-radius: 100px;
    background: var(--color-background-key);
    color: var(--color-text-bright);
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      padding: 14px 15px;
    }
  }

  & > .item > .button {
    display: block;
    padding: 12px 13px;
    color: var(--color-text);
    text-align: center;
    text-decoration: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      padding: 14px 15px;
    }
  }

  & > .item > .button > .arrow {
    width: ${rem(10)};
    margin-top: 0.15em;
  }

  & > .item > .button > .arrow path {
    fill: var(--color-text);
  }
`

const PagerComponent = ({ pager }: PagerProps) => {
  const pagerItems = []
  for (let i = 0; i < pager.totalPage; i++) {
    const path = i === 0 ? pager.path : `${pager.path}/page/${i + 1}`
    const item = (
      <li className="item" key={i}>
        {pager.currentPage === i + 1 ? (
          <span className="current">{i + 1}</span>
        ) : (
          <Link className="button" to={path}>
            {i + 1}
          </Link>
        )}
      </li>
    )
    pagerItems.push(item)
  }
  return (
    <ul className={pagerStyle}>
      <li className="item">
        {1 < pager.currentPage && (
          <Link
            className="button"
            to={pager.currentPage === 2 ? pager.path : `${pager.path}/page/${pager.currentPage - 1}`}
          >
            <IconArrow className="arrow" />
          </Link>
        )}
      </li>
      {pagerItems.map((item) => item)}
      <li className="item">
        {pager.currentPage < pager.totalPage && (
          <Link className="button" to={`${pager.path}/page/${pager.currentPage + 1}`}>
            <IconArrow className="arrow" />
          </Link>
        )}
      </li>
    </ul>
  )
}

export default PagerComponent
